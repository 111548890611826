import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import idkNetworkLogo from "../resources/images/network.png";
import idkLogo from "../resources/images/idkBlackLogo.png";
import fiveLogo from "../resources/images/over500.png";
import { Link, BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Idk from "../routes/Idk";
import Landing from "../routes/Landing";
import Over500 from "../routes/Over500";
import { Paper } from "@material-ui/core";

// Function for Navbar
export default function NavBar() {
  const useStyles = makeStyles(() =>
    createStyles({
      navBar: {
        backgroundColor: "#0e0f0f",
        flexGrow: 'inherit',
      },
      networkImg: {
        height: "3em",
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: ".25em",
        marginTop: ".25em",
        padding: '.5em'
      },
      gridContainer: {
        marginTop:'1em',
        justifyContent: "center",
      },
      navGridItem: {},
    })
  );
  const classes = useStyles();
  return (
    <Router>
      <AppBar position="sticky" className={classes.navBar}>
        <Grid className={classes.gridContainer}>

        </Grid>
        <Switch>
          <Route path="/Idk">
            <Idk />
          </Route>
          <Route path="/Over500">
            <Over500 />
          </Route>
          <Route path="/">
            <Idk />
          </Route>
        </Switch>
      </AppBar>
    </Router>
  );
}
