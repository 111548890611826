import { Paper } from "@material-ui/core";
import React from "react";
import "../App.css";
import NavBar from "./NavBar";


function App() {
  return (
    <React.Fragment>

     
      <NavBar />

    </React.Fragment>
  );
}

export default App;
