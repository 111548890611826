import React from "react";
import { Link, BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ResponsiveEmbed from "react-responsive-embed";
import "../App.css";
import TwitterIcon from "@material-ui/icons/Twitter";
import AppleIcon from "@material-ui/icons/Apple";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import FacebookIcon from "@material-ui/icons/Facebook";
import idkMain from "../resources/images/idkLogoLarge.png";
import BoatBanner from "../resources/images/idkboat-01.png";
import YouTubeIcon from "@material-ui/icons/YouTube";
import {
  createStyles,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { Icon } from "@iconify/react";
import { Instagram } from "@material-ui/icons";

function Idk() {
  var imgSize = "";
  const useStyles = makeStyles(() =>
    createStyles({
      // STYLING
      Mainimg: {
        height: "auto",
        width: imgSize,
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: "1em",
        marginTop: "3em",
      },
      BoatBanner: {
        height: "auto",
        width: imgSize,
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: "2rem",
        marginTop: ".5rem",
      },

      gridContainer: {
        justifyContent: "center",
      },
      navGridItem: {},
    })
  );
  const isLargeScreen = useMediaQuery("(min-width:1280px)");
  const isMediumScreen = useMediaQuery("(max-width:1280px)");
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  var title;
  var padding;
  var fontSize;
  var iconSize;
  if (isLargeScreen) {
    padding = "0 3.5rem";
    fontSize = "1.8rem";
    iconSize = "3rem";
    imgSize = "80%";
    title = "3.5rem";
  }
  if (isMediumScreen) {
    padding = "0 2.5rem";
    fontSize = "1.5rem";
    iconSize = "2rem";
    imgSize = "90%";
    title = "2.5rem";
  }
  if (isSmallScreen) {
    padding = "0 .25rem";
    fontSize = ".8rem";
    iconSize = "";
    imgSize = "100%";
    title = "1.5rem";
  }

  // STYLING

  const classes = useStyles();
  return (
    <React.Fragment>
      <Grid
        container
        spacing={1}
        direction="row"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        wrap="nowrap"
        style={{ textAlign: "center", maxWidth: "66%", margin: "auto" }}
      >
        {/* MAIN-IMAGE */}
        <a href="https://www.youtube.com/watch?v=6iRu9gM9drw">
          <img
            src={idkMain}
            className={classes.Mainimg}
            alt="IDK Network Logo"
          />
        </a>
      </Grid>
      {/* MAIN-IMAGE */}
      <Grid style={{ textAlign: "center" }}>
        {/* INTRO */}
        <Typography
          style={{ textAlign: "center", padding, fontSize }}
          variant="h6"
        >
          Austin, Texas has a burgeoning Hip Hop/Rap and R&B scene, that not
          many tend to paying attention to. Some would say that’s due to lack of
          media outlets covering that particular side of Austin music. So like
          most times in the Live Music Capital people have taken things into
          their own hands to cover the rap scene that’s steadily growing.
        </Typography>
        <br />
        <Typography
          style={{ textAlign: "center", padding, fontSize }}
          variant="h6"
        >
          Enter IDK...That’s Perfect Podcast. Birthed in 2019, host Mario from
          the Southside sits down with rising stars in the ATX and surrounding
          areas Hip Hop scene and discuss an array of topics with them.
        </Typography>
        <br />
        <Typography
          style={{ textAlign: "center", padding, fontSize }}
          variant="h6"
        >
          The Austin Drink Champs esque show has dubbed themselves the most
          professional unprofessional out of South Austin. That alone prepares
          you for an entertaining listen. The self represented Mario from the
          south side lets it be known that he has no journalistic background,
          but somehow makes the show insightful as well as funny.
        </Typography>
        {/* INTRO */}
        {/* SOCIAL */}
        <Grid item style={{ margin: "2.5em" }}>
          {/* Twitter logo */}
          <a
            target={"_blank"}
            rel={"noreferrer"}
            style={{ textDecoration: "none", color: "white", margin: ".5em" }}
            href="https://twitter.com/IDKTHATSPERFECT"
          >
            <TwitterIcon style={{ fontSize: iconSize }} />
          </a>
          {/* Instagram */}
          <a
            target={"_blank"}
            rel={"noreferrer"}
            style={{ textDecoration: "none", color: "white", margin: ".5em" }}
            href="https://www.instagram.com/idkthatsperfect1/"
          >
            <Instagram style={{ fontSize: iconSize }} />
          </a>

          {/* Facebook logo */}
          <a
            target={"_blank"}
            rel={" noreferrer"}
            style={{ textDecoration: "none", color: "white", margin: ".5em" }}
            href="https://www.facebook.com/idkthatsperfect/"
          >
            <FacebookIcon style={{ fontSize: iconSize }} />
          </a>

          {/* Spotify logo */}
          {/* <a
            target={"_blank"}
            rel={"noreferrer"}
            style={{ textDecoration: "none", color: "white", margin: ".5em" }}
            href="https://open.spotify.com/show/5mCNJFJN78Uc9KvFFkAi6C?si=0cfeb6a3c3fc4b32"
          >
            <Icon icon="mdi:spotify" height="24" />
          </a> */}
          {/* Apple logo */}
          {/* <a
            target={"_blank"}
            rel={"noreferrer"}
            style={{ textDecoration: "none", color: "white", margin: ".5em" }}
            href="https://podcasts.apple.com/us/podcast/idk-thats-perfect/id1453639753"
          >
            <AppleIcon />
          </a> */}
          {/* YouTube logo */}
          <a
            target={"_blank"}
            rel={"noreferrer"}
            style={{ textDecoration: "none", color: "white", margin: ".5em" }}
            href="https://www.youtube.com/c/IDKThatsPerfect"
          >
            <YouTubeIcon style={{ fontSize: iconSize }} />
          </a>
        </Grid>
      </Grid>

      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={10} md={8} lg={6} xl={4}>
          <Typography style={{ textAlign: "center", fontSize: `${title}` }}>
            LATEST EPISODES
          </Typography>
        </Grid>
      </Grid>
      {/* VIDEOS */}
      {/* Playlist */}
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={10} md={8} lg={6} xl={4}>
          <div style={{ width: "100%", marginBottom: "3rem" }}>
            <ResponsiveEmbed
              allowFullScreen
              src="https://www.youtube.com/embed/videoseries?list=PLp2ZQ4iIJPQcy4-GpLfqFemZRz1DLyLf8&autoplay=1&mute=1&index=0&sort=dd&"
            />
          </div>
        </Grid>
      </Grid>

      {/* <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={10} md={8} lg={6} xl={4}>
          <div style={{ width: "100%" }}>
            <ResponsiveEmbed src="https://open.spotify.com/embed/episode/2Rk9E393K7j4tdLF1Ipnyq?utm_source=generator" />
          </div>
        </Grid>
      </Grid> */}
    </React.Fragment>
  );
}

export default Idk;
